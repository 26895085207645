import React from "react"
import { graphql } from "gatsby"
import Helmet from 'react-helmet'

import Hero from "../components/sections/hero/hero"

import Layout from "../components/theme/theme"

import "../components/sections/section/section.css"

const IndexPage = props => {
  const heroImages = [
    props.data.mobileHero.childImageSharp.fluid,
    {
      ...props.data.desktopHero.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <Layout>
      <Helmet title="Contact us to find out more" />

      <Hero image={heroImages} textcolor="light" subtitle="Get in touch" title="Contact us at CBW Limited" />

      <section className="sec-Section">
        <div className="sec-Section_Inner lyt-Container">
          <div className="sec-Section_Header">
            <div className="sec-Section_Inset">
              <h3 className="sec-Section_SubTitle">
                Do you want to learn more?
              </h3>

              <h2 className="sec-Section_Title">
                Use one of the methods below to contact us
              </h2>
            </div>
          </div>

          <div className="sec-Section_Body">
            <div className="sec-Section_Inset">
              <p>CBW Limited aim to provide a single point of contact for all your requirements. We strive to provide a fully managed product, from order recept through to home owner satisfaction receipt.</p>

              <p>Telephone: <a className="sec-Section_Link" href="tel:01438316400" target="_blank" rel="noopener noreferrer">01438 316 400</a></p>

              <p>Email: <a className="sec-Section_Link" href="mailto:info@cbwlimited.com" target="_blank" rel="noopener noreferrer">info@cbwlimited.com</a></p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    desktopHero: file(relativePath: { eq: "contact-hero.jpg" }) {
      ...fluidImage
    }
    mobileHero: file(relativePath: { eq: "contact-hero-mobile.jpg" }) {
      ...fluidImage
    }
  }
`;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 2880) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
